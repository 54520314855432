import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {
  nav,
  prevBox,
  nextBox,
  icon,
  indicator
} from './PageNavigation.module.scss'

/**
 * Render the PageNavigation component.
 * @author                         Content Pilot
 * @param  {object}  props          The component attributes as props.
 * @param  {object}  props.previous The previous page object.
 * @param  {object}  props.next     The next page object.
 * @return {Element}                The PageNavigation component.
 */
export default function PageNavigation({previous, next}) {
  return (
    <nav className={nav}>
      {previous && (
        <Link to={`/${previous.slug}`} rel="prev" className={prevBox}>
          <FontAwesomeIcon
            className={icon}
            icon={['fal', 'long-arrow-left']}
            aria-label="Arrow left"
          />
          <div>
            <span className={indicator}>Previous</span>
            <h4 dangerouslySetInnerHTML={{__html: previous.title}}></h4>
          </div>
        </Link>
      )}
      {next && (
        <Link to={`/${next.slug}`} rel="next" className={nextBox}>
          <div>
            <span className={indicator}>Next</span>
            <h4 dangerouslySetInnerHTML={{__html: next.title}}></h4>
          </div>
          <FontAwesomeIcon
            className={icon}
            icon={['fal', 'long-arrow-right']}
            aria-label="Arrow right"
          />
        </Link>
      )}
    </nav>
  )
}

PageNavigation.propTypes = {
  previous: PropTypes.object,
  next: PropTypes.object
}
