// extracted by mini-css-extract-plugin
export var bounce = "PageNavigation-module--bounce--40b7a";
export var dotMoveLeft = "PageNavigation-module--dot-move-left--93921";
export var dotMoveRight = "PageNavigation-module--dot-move-right--35ee1";
export var gradient = "PageNavigation-module--gradient--88fc9";
export var icon = "PageNavigation-module--icon--41bbd";
export var indicator = "PageNavigation-module--indicator--5fd4f";
export var nav = "PageNavigation-module--nav--3db59";
export var nextBox = "PageNavigation-module--nextBox--75a1c";
export var prevBox = "PageNavigation-module--prevBox--1ff31";
export var separatorWidth = "PageNavigation-module--separator-width--965ae";