import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import Content from 'organisms/Content'
import SEO from 'molecules/Seo'
import MenuSecondary from 'molecules/MenuSecondary'
import PageHeader from 'molecules/PageHeader'
import Main from 'organisms/Main'
import Blocks from 'molecules/Blocks'
import Sidebar from 'organisms/Sidebar'
import StickyNavigation from 'molecules/StickyNavigation'
import PageNavigation from 'molecules/PageNavigation'

const converter = require('number-to-words')

/**
 * Template Fbp Single
 * @author                            Content Pilot
 * @param  {object}  props             The component attributes as props.
 * @param  {object}  props.data        The page data.
 * @param  {object}  props.pageContext The page context.
 * @return {Element}                   The Fbp Single template.
 */
export default function SingleFbp({data, pageContext}) {
  const {blocks} = pageContext
  const {previous, next, wpFbp} = data
  const {title, menuOrder, seo} = wpFbp
  const {metaTitle, metaDesc, opengraphType, opengraphUrl, opengraphImage} = seo

  const word = converter.toWords(menuOrder)

  return (
    <Content className={`fbp ${word}`}>
      <SEO
        title={metaTitle}
        metaDesc={metaDesc}
        opengraphType={opengraphType}
        opengraphUrl={opengraphUrl}
        opengraphImage={opengraphImage}
      />
      <MenuSecondary />
      <PageHeader title={title} />
      <Content internal>
        <Main>
          <Blocks blocks={blocks} />
        </Main>
        <Sidebar>
          <StickyNavigation blocks={blocks} />
        </Sidebar>
      </Content>
      <PageNavigation previous={previous} next={next} />
    </Content>
  )
}

SingleFbp.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  pageContext: PropTypes.object
}

export const query = graphql`
  query FbpById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    wpFbp(id: {eq: $id}) {
      title
      menuOrder
      seo {
        metaTitle: title
        metaDesc
        opengraphType
        opengraphUrl
        opengraphImage {
          mediaItemUrl
        }
      }
    }
    # this gets us the previous post by id (if it exists)
    previous: wpFbp(id: {eq: $previousPostId}) {
      slug
      title
    }
    # this gets us the next post by id (if it exists)
    next: wpFbp(id: {eq: $nextPostId}) {
      slug
      title
    }
  }
`
