import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import './PageHeader.scss'

/**
 * Render the PageHeader component.
 * @see                   {@link https://ant.design/components/list/}
 * @author                Content Pilot
 * @param  {string}  title The page header title.
 * @return {Element}       The PageHeader component.
 */
export default function PageHeader({title}) {
  return (
    <Fragment>
      <section className="page-header" aria-label="Page header">
        <h1 dangerouslySetInnerHTML={{__html: title}}></h1>
        <span className="page-header__word" />
      </section>
    </Fragment>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired
}
